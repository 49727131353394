var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"courier","suppliers":_vm.suppliers,"features":{
    detail: true,
    search: true,
    create: true,
    update: true,
    delete: false,
  }},scopedSlots:_vm._u([{key:"row",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('h5',[_c('a-tooltip',{attrs:{"title":_vm.$t(("enum.activation." + (item.activation))),"placement":"left"}},[_c('a-badge',{attrs:{"status":_vm.activation[item.activation].color}})],1),_vm._v(" "+_vm._s(item.contact.name)+" ")],1),(item.company)?_c('j-label',{attrs:{"icon":"usergroup-add"}},[_vm._v(" "+_vm._s(item.company)+" ")]):_c('j-label',{attrs:{"icon":"user"}},[_vm._v(" "+_vm._s(_vm.$t('enum.courierType.SINGLE'))+" ")]),_c('j-label',{attrs:{"icon":"number"}},[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('j-label',{attrs:{"icon":"environment"}},[_c('j-address',{attrs:{"value":item.address}})],1),_c('j-label',{attrs:{"icon":"mail"}},[_c('j-email',{attrs:{"value":item.contact.email}})],1),_c('j-label',{attrs:{"icon":"phone"}},[_c('j-phone',{attrs:{"value":item.contact.mobile || item.contact.phone}})],1),_c('j-label',{attrs:{"icon":"car"}},[_vm._v(" "+_vm._s(_vm.$t(("enum.vehicle." + (item.vehicle))))+" ")])],1)])])])]}},{key:"avatar",fn:function(ref){
  var item = ref.item;
return [_c('a-avatar',{staticClass:"bg-default",attrs:{"icon":"qrcode","shape":"square","size":48,"role":"button"},on:{"click":function () { return _vm.onDispatch(item); }}})]}},{key:"actions",fn:function(ref){
  var item = ref.item;
return [_c('j-actions',{attrs:{"type":"icon","item":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }