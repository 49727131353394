<template>
  <j-actions :type="type">
    <a-menu>
      <a-menu-item
        @click="onEdit"
        v-if="$auth.granted(acl.couriers.update)">
        <a-icon type="form" class="text-secondary" />
        {{ $t('msg.courier.action.edit') }}
      </a-menu-item>
      <a-menu-item
        @click="onWaybill"
        v-if="$auth.granted(acl.couriers.search)">
        <a-icon type="printer" class="text-secondary" />
        {{ $t('msg.courier.action.waybill') }}
      </a-menu-item>
      <a-menu-item
        @click="onLabels"
        v-if="$auth.granted(acl.couriers.search)"
        class="text-danger">
        <a-icon type="qrcode" class="text-danger" />
        {{ $t('msg.courier.action.labels') }}
      </a-menu-item>
      <a-menu-item
        @click="onActivate"
        v-if="$auth.granted(acl.couriers.update) && item.activation !== 'ACTIVE'">
        <a-icon type="check-circle" class="text-secondary" />
        {{ $t('msg.courier.action.activate') }}
      </a-menu-item>
      <a-menu-item
        class="text-danger"
        @click="onDeactivate"
        v-if="$auth.granted(acl.couriers.update) && item.activation === 'ACTIVE'">
        <a-icon type="close-circle" class="text-danger" />
        {{ $t('msg.courier.action.deactivate') }}
      </a-menu-item>
    </a-menu>
  </j-actions>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import JActions from '@/views/shared/base/actions'
export default {
  props: [
    'item',
    'type',
  ],
  components: {
    JActions,
  },
  data: function () {
    return {
      acl,
    }
  },
  methods: {
    onWaybill() {
      lms.courier.fetchWaybill(this.item.id)
        .then(response => window.open(response.data.url, '_blank'))
    },
    onLabels() {
      lms.courier.fetchLabels(this.item.id)
        .then(response => window.open(response.data.url, '_blank'))
    },
    onEdit() {
      this.$router.push(`/couriers/${this.item.id}/update`)
    },
    onActivate() {
      lms.courier.activate(this.item.id)
        .then(() => this.$message.success(this.$t('msg.courier.activated')))
    },
    onDeactivate() {
      lms.courier.deactivate(this.item.id)
        .then(() => this.$message.success(this.$t('msg.courier.deactivated')))
    },
  },
}
</script>
