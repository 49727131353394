<template>
  <j-abm
    name="courier"
    :suppliers="suppliers"
    :features="{
      detail: true,
      search: true,
      create: true,
      update: true,
      delete: false,
    }">
    <!-- Item -->
    <template #row="{item}">
      <div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <h5>
                <!-- Activation -->
                <a-tooltip
                  :title="$t(`enum.activation.${item.activation}`)"
                  placement="left">
                  <a-badge :status="activation[item.activation].color"/>
                </a-tooltip>
                <!-- Name -->
                {{ item.contact.name }}
              </h5>
              <!-- Company -->
              <j-label icon="usergroup-add" v-if="item.company">
                {{ item.company }}
              </j-label>
              <j-label icon="user" v-else>
                {{ $t('enum.courierType.SINGLE') }}
              </j-label>
              <!-- Courier ID -->
              <j-label icon="number">
                {{ item.id }}
              </j-label>
            </div>
            <div class="col-12 col-md-6">
              <!-- Address -->
              <j-label icon="environment">
                <j-address :value="item.address" />
              </j-label>
              <!-- Email -->
              <j-label icon="mail">
                <j-email :value="item.contact.email" />
              </j-label>
              <!-- Phone -->
              <j-label icon="phone">
                <j-phone :value="item.contact.mobile || item.contact.phone" />
              </j-label>
              <!-- Vehicle -->
              <j-label icon="car">
                {{ $t(`enum.vehicle.${item.vehicle}`) }}
              </j-label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #avatar="{item}">
      <a-avatar
        class="bg-default"
        icon="qrcode"
        shape="square"
        :size="48"
        role="button"
        @click="() => onDispatch(item)">
      </a-avatar>
    </template>
    <template #actions="{item}">
      <j-actions type="icon" :item="item" />
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import { activation } from '@/utils/styles'
import JAbm from '@/views/shared/abm/search'
import JLabel from '@/views/shared/labels/text'
import JAddress from '@/views/shared/labels/address'
import JEmail from '@/views/shared/labels/email'
import JPhone from '@/views/shared/labels/phone'
import JActions from './actions'
export default {
  components: {
    JAbm,
    JLabel,
    JAddress,
    JEmail,
    JPhone,
    JActions,
  },
  data: function () {
    return {
      acl,
      activation,
    }
  },
  computed: {
    suppliers() {
      return {
        search: query => lms.courier.search(query),
        fetchAll: page => lms.courier.fetchAll(page),
      }
    },
  },
  methods: {
    onDispatch(item) {
      this.$notification.open({
        message: item.contact.name,
        duration: 30,
        description: h => <img src={`https://chart.apis.google.com/chart?cht=qr&chs=340x340&chl=${item.code}:X`} />,
        placement: 'topRight',
      })
    },
  },
}
</script>
